import React, { useEffect, useState } from 'react';
import Appbar from 'components/appbar/Appbar';
import ModuleLoading from 'components/loading/ModuleLoading';
import TableLoading from 'components/loading/TableLoading';
import PageHeaderActions from 'components/page-header/PageHeaderActions';
import useTableOrder from 'hooks/tableOrder';
import NoData from 'components/no-data/NoData';
import PaginationProvider from 'hooks/pagination';
import { useDisplayMode } from 'hooks/useDisplayMode';
import { useFetchItemEntry } from './hooks/useFetchItemEntry';
import { ItemEntry } from 'types/itemEntry';
import ItemEntryListTable from './list/table/ItemEntryListTable';
import ItemEntryFilterBox from './ItemEntryFilterBox';
import ItemEntryActions from './ItemEntryActions';
import history from 'services/history';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';
import ItemEntryTabs from './tab/ItemEntryTabs';
import ItemEntryDashboard from './dashboard/ItemEntryDashboard';
import Pagination from 'components/pagination/Pagination';
import ItemEntryListModule from './list/module/ItemEntryListModule';

export interface ItemsSoldFilter {
  initialDate: Date | null;
  finalDate: Date | null;
  branchId: number;
}

const ItemEntryPage: React.FC = () => {
  const [displayMode] = useDisplayMode();
  const [tab, setTab] = useState<'items' | 'dashboard'>('dashboard');
  const { loading, entries, filter, handleChangeFilter, onSearch } = useFetchItemEntry();
  const [orderedIndex, sort] = useTableOrder();
  const [filtered, setFiltered] = useState<ItemEntry[]>([]);

  useEffect(() => {
    setFiltered(entries);
  }, [entries]);

  function handleSort(index: string) {
    const filtered = sort(index, entries);
    setFiltered(filtered);
  }

  function handleReportClick() {
    const productId = parseInt(filter.product);
    history.push({
      pathname: '/reports/item_entry/report',
      search: `id_filial=${filter.branch_id || ''}&cliente=${filter.customer}&data_ini=${format(
        filter.initial_date,
        'P',
        {
          locale: ptBR,
        },
      )}&data_fim=${format(filter.final_date, 'P', { locale: ptBR })}&produto=${
        isNaN(productId) ? filter.product : filter.product.length === productId.toString().length ? '' : filter.product
      }&id_produto=${isNaN(productId) ? '' : filter.product.length === productId.toString().length ? productId : ''}
      `,
    });
  }

  return (
    <>
      <Appbar title="Entrada de itens" />
      <PageHeaderActions
        title="Entrada de itens"
        ActionComponent={<ItemEntryActions entries={entries} handleReportClick={handleReportClick} />}
      />

      <ItemEntryFilterBox
        filter={filter}
        handleChangeFilter={handleChangeFilter}
        loading={loading}
        onSearch={onSearch}
      />

      <ItemEntryTabs tab={tab} handleChange={value => setTab(value)} />

      {loading ? (
        displayMode === 'list' ? (
          <TableLoading />
        ) : (
          <ModuleLoading />
        )
      ) : tab === 'items' ? (
        filtered.length === 0 ? (
          <NoData message="Nenhum item para mostrar" />
        ) : (
          <PaginationProvider>
            <div>
              {displayMode === 'list' ? (
                <ItemEntryListTable entries={filtered} handleSort={handleSort} orderedIndex={orderedIndex} />
              ) : (
                <ItemEntryListModule entries={filtered} />
              )}
              <Pagination count={filtered.length} />
            </div>
          </PaginationProvider>
        )
      ) : (
        <ItemEntryDashboard items={entries} />
      )}
    </>
  );
};

export default ItemEntryPage;
