import FilterBox from 'components/filter-box/FilterBox';
import SelectBranch from 'components/select-branches/SelectBranch';
import React, { FormEvent } from 'react';
import KeyboardDatePicker from 'components/pickers/DesktopDatePicker';
import { Button, MenuItem, styled, TextField } from '@mui/material';
import { FilterItemEntry } from './hooks/useFetchItemEntry';

interface ItemEntryFilterBoxProps {
  filter: FilterItemEntry;
  onSearch(filter: FilterItemEntry): void;
  handleChangeFilter(index: keyof FilterItemEntry, value: any);
  loading: boolean;
}

const Filter = styled('form')(({ theme }) => ({
  gap: 15,
  zoom: '80%',
  display: 'grid',
  gridTemplateColumns: '200px 400px 160px 1fr 1fr 100px',
  [theme.breakpoints.down('lg')]: {
    gridTemplateColumns: '1fr 2fr 160px 160px 160px 80px',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr 140px',
  },
  [theme.breakpoints.down('sm')]: {
    gap: 15,
    gridTemplateColumns: '1fr',
  },
}));

const DateContainer = styled('form')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 15,
}));

const ItemEntryFilterBox: React.FC<ItemEntryFilterBoxProps> = ({ handleChangeFilter, filter, onSearch, loading }) => {
  function onSubmit(e?: FormEvent<HTMLFormElement>) {
    e?.preventDefault();

    onSearch(filter);
  }

  return (
    <FilterBox>
      <Filter onSubmit={onSubmit}>
        <SelectBranch handleChange={value => handleChangeFilter('branch_id', value)} branch_id={filter.branch_id} />

        <DateContainer>
          <KeyboardDatePicker
            label="Data inicial"
            value={filter.initial_date || null}
            onChange={date => handleChangeFilter('initial_date', date)}
          />

          <KeyboardDatePicker
            label="Data final"
            value={filter.final_date || null}
            onChange={date => handleChangeFilter('final_date', date)}
          />
        </DateContainer>

        <TextField
          label="Selecione o tipo"
          select
          onChange={e => handleChangeFilter('type', e.target.value)}
          value={filter.type}
        >
          <MenuItem value="all">Todos</MenuItem>
          <MenuItem value="C">Compras</MenuItem>
          <MenuItem value="T">Transferências</MenuItem>
          <MenuItem value="O">Outros</MenuItem>
        </TextField>

        <TextField
          placeholder="Digite o nome ou CNPJ do fornecedor"
          label="Fornecedor/CNPJ"
          value={filter.customer}
          onChange={e => handleChangeFilter('customer', e.target.value)}
        />

        <TextField
          placeholder="Digite o nome ou código do produto"
          label="Produto/Código"
          value={filter.product}
          onChange={e => handleChangeFilter('product', e.target.value)}
        />

        <Button disabled={loading} variant="contained" type="submit">
          Buscar
        </Button>
      </Filter>
    </FilterBox>
  );
};

export default ItemEntryFilterBox;
